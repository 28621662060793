html, body {
  background:rgba(0,0,0,0);
}

#root {
  font-family: 'Khand', sans-serif;
  color:#231f20;
}

section {
  background: #fff;
}

.nav-item > span {
  display: block;
  padding: 0.5rem 0.5rem 1rem;
}

.twitch-login {
  width:auto;
  height:40px;
}

.navbar-brand {
  position: relative;
  z-index: 98;
}

.navbar-brand img {
  position: absolute;
  z-index: 99;
  top:-10px;
}

#root .navbar-nav {
  padding:15px 0;
}

#root > .navbar .nav-item {
  color:#231f20;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

#root > .navbar .nav-item a {
  color:#231f20;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding:0;
}

#root > .navbar .nav-item span {
  display: inline-block;
  padding:0;
}

#root > .navbar .nav-item span.material-icons {
  padding-bottom: 3px;
}

#root > .navbar .nav-item {
  padding:0 10px;
}

#root > .navbar .nav-item:last-child {
  padding-right:0;
  display: flex;
  align-items: center;
}

#root > .navbar .nav-item a.nav-cta {
  color:#fff;
  background:#00b4f1;
  padding:3px 20px 1px;
  font-weight: 700;
  transition: all 0.5s;
}

#root > .navbar .nav-item a.nav-cta:hover {
  background:#b1cc49;
}

.hero {
  padding:120px 0 300px;
  background: url(/img/hero-bg.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.hero-sm {
  padding:60px 0 200px;
}

.hero-water {
  position: absolute;
  z-index: 2;
  width:100%;
  bottom:0;
  left:0;
  padding-top:8%;
  background: url(/img/hero-water.png) repeat-x center center;
  background-size: auto 100%;
}

.hero h1 {
  font-weight: 700;
  text-transform: uppercase;
  color:#00b4f1;
  font-size: 89px;
  line-height: 1em;
}

.hero h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 43px;
  line-height: 1em;
}

.hero-counter {
  margin-top:80px;
  background:#231f20;
  padding:10px 30px;
  color:#fff;
  display: inline-flex;
  align-items: center;
}

.counter {
  padding-right:80px;
  min-width:415px;
  text-align: center;
}

.hero-counter .odometer-block {
  text-transform: uppercase;
  text-align: center;
  color:#00b4f1;
  line-height: 1em;
  font-weight: 600;
}

.hero-counter .odometer-block > span {
  color:#fff;
  font-size:34px;
}

.hero-counter .odometer-block > span > span {
  color:#00b4f1;
  font-size: 54px;
  line-height: 54px;
  display: inline-block;
  min-width: 49px;
  text-align: center;
}

.timer-label {
  text-transform: uppercase;
  font-size: 28px;
  line-height: 1em;
  text-align: center;
  font-weight: 600;
}

section {
  padding:100px 0;
}

section.about {
  padding:20px 0 0;
}

section.phone {
  padding:0;
}

a.btn, button.btn {
  color:#fff;
  background:#00b4f1;
  padding:3px 30px 1px;
  font-weight: 700;
  text-transform: uppercase;
  border:none;
  border-radius: 0;
  font-size: 30px;
  transition: all 0.5s;
}

a.btn:hover, button.btn:hover {
  background:#b1cc49;
  color:#fff;
}

h3 {
  color:#265aa5;
  text-transform: uppercase;
  font-size: 57px;
  font-weight: 700;
}

h4 {
  color:#00b4f1;
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 700;
}

p {
  font-size: 25px;
  font-weight: 400;
}

ol, ul {
  font-size: 22px;
}

.carousel-root {
  margin:50px 0;
}

.carousel .slide {
  background:none;
}

.carousel.carousel-slider {
  z-index: 1;
}

.carousel .control-dots {
  bottom:55px;
  padding-right: 155px;
  text-align: right;
}

.carousel .control-dots .dot {
  width:14px;
  height:14px;
  box-shadow:none;
  background-color:#265aa5;
  text-align: right;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  background-color:#00b4f1;
}

.carousel-labels {
  position: relative;
  z-index: 2;
  margin-top: -73px;
  margin-bottom:100px;
}

.carousel-labels h4 {
  background-color:#fff;
  display: inline-block;
  padding-right: 15px;
}

.hero-2 {
  background: url(/img/hero-bg-2.jpg) no-repeat center center;
  padding:100px 0;
  background-size: cover;
}

.pricing {
  background: url(/img/hero-bg-3.jpg) no-repeat center center;
  padding:100px 0;
  background-size: cover;
}

.two-up {
  display: table;
  padding-top:20px;
}

.up {
  display: table-cell;
}

.up:first-child {
  padding-right:20px;
}

.up:last-child {
  padding-left:20px;
}

.two-up .up .two-up-inner {
  background:rgba(255,255,255,0.6);
  padding:40px 50px;
}

footer {
  text-align: center;
  padding:30px 0 100px;
}

footer p {
  font-size: 18px;
  margin:0;
  padding:0;
  line-height: 1em;
}

.price-compare {
  padding-top:40px;
  display: table;
  width:100%;
  text-align: center;
}

.price-compare .compare {
  display: table-cell;
}

.price-compare .compare:nth-child(2) .price-content {
  background:rgba(240,240,240,0.7);
}

.price-compare h4 {
  color:#fff;
  padding:20px 0 14px;
  line-height: 1em;
  margin:0;
}

.price-content {
  background:rgba(255,255,255,0.7);
  padding:20px 0;
}

.price-compare .open h4 {
  background:#ababa7;
}

.price-compare .elite h4 {
  background:#afc656;
}

.price-compare .compare span:first-child {
  border-top:none;
}

.price-compare .compare span {
  display: block;
  padding:15px 0;
  font-size: 26px;
  font-weight: 500;
  border-top:2px solid #f6f7ed;
}
.navbar-nav.mobile-menu {
  display: none;
}

.mobile-menu .material-icons {
  font-size: 40px;
  line-height: 40px;
}

.join-box{
  margin-top: 50px;
  padding:100px 0;
  background:rgba(255,255,255,0.6);
}
.steps-bar {
  margin:0 100px;
  position: relative;
  display: flex;
}
.steps-bar .progress-track {
  position: absolute;
  top: 6px;
  left:17%;
  width: 66%;
  height: 8px;
  background-color: rgba(35,31,32,0.3);
  z-index: -1;
}
.steps-bar .progress-step {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  color:#777;
  font-weight: 600;
}
.steps-bar .progress-step:last-child:after {
  display: none;
}
.steps-bar .progress-step:before {
  content: " ";
  display: flex;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 3px solid #777;
  border-radius: 100%;
  color: transparent;
}
.steps-bar .progress-step:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 50%;
  width: 0%;
  transition: width 1s ease-in;
  height: 8px;
  background: #dfe3e4;
  z-index: -1;
}
.steps-bar .progress-step.is-active {
  color: #00b4f1;
}
.steps-bar .progress-step.is-active:before {
  border: 4px solid #00b4f1;
  animation: pulse 2s infinite;
}
.steps-bar .progress-step.is-complete {
  color: #00b4f1;
}
.steps-bar .progress-step.is-complete:before {
  font-family: FontAwesome;
  font-size: 10px;
  color: #fff;
  background: #00b4f1;
  border: 4px solid transparent;
}
.steps-bar .progress-step.is-complete:after {
  background: #00b4f1;
  animation: nextStep 1s;
  animation-fill-mode: forwards;
}

.questions {
  width:50%;
  text-align: left;
  margin:0 auto;
  padding:50px 0;
  font-size: 22px;
}

.questions label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
}

.questions .formline {
  padding-bottom:25px;
}

select, textarea {
  padding:5px 15px 2px;
  font-size: 18px;
}

textarea {
  width:100%;
  min-height:120px;
}

th {
  text-transform: uppercase;
  background:#265aa5;
  color:#fff;
}

th, td {
  padding:10px 20px;
  text-align: center;
}

th:first-child, td:first-child {
  text-align: left;
  font-weight: 700;
}

.initial-cap {
  text-transform: capitalize;
}

.bold {
  font-weight: 700;
}

table .bold {
  color:#00b4f1;
}

table.totals {
  margin-bottom: 50px;
}

table.totals td {
  font-size: 1.5em;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0,180,241, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0,180,241, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,180,241, 0);
  }
}
@keyframes nextStep {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.twitch-connect {
  text-align: center;
  padding:50px 0;
}

.error select {
  border:1px solid #cc4949;
}

p.errMsg {
  color:#cc4949;
  border:1px solid #cc4949;
  padding:15px 25px;
}

p.successMsg {
  color:#508f1c;
  border:1px solid #508f1c;
  padding:15px 25px;
}

button.btn-cancel {
  margin-left: 20px;
  background-color:#777;
}

button.btn-cancel:hover {
  background-color:#cc4949;
  color:#fff;
}

.disclaimer {
  text-align: center;
  padding:25px 0 0;
  font-size:1.2em;
  color:#686868;
}

.socials {
  padding-bottom:20px;
}

.socials .social {
  display: inline-block;
  margin-right:10px;
}

.socials .social img {
  width:40px;
  height:auto;
}

a.btn-discord {
  background:#7887c2;
  margin-left:25px;
}
button.btn-error {
  background:#cc4949;
}

.overlay {
  z-index: 1;
  position: relative;
  width:1920px;
  height:1080px;
  overflow: hidden;
  /* border-right:1px solid #000;
  border-bottom:1px solid #000; */
}

.weather {
  position: absolute;
  z-index: 2;
  top:0;
}

.weather.weather-vertical::before {    
  content: "";
  background:url(/img/weather-bg.jpg) repeat center center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 1;
  z-index: 1;
}

.catches-icon {
  position: absolute;
  z-index: 2;
  top:45px;
  right:70px;
  text-align: center;
}

.catches-icon .catches-inner {
  width:100px;
  height:100px;
  margin:0 auto;
  position: relative;
}

.catches-icon .catches-label {
  display: none;
  text-align: center;
  color: #00b4f1;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.4em;
  letter-spacing: 0.5px;
  text-shadow: 0 0 2px #000;
  margin:0 auto;
}

.catches-icon .catches-count {
  display: block;
  text-align: center;
  color:#fff;
  text-shadow: 0 0 2px #000;
  font-weight: 700;
  font-size: 3em;
  padding:22px 0 0 22px;
  position: relative;
  z-index: 9;
}

.catches-icon .catches-inner::before {    
  content: "";
  background:url(/img/run-catch-icon.png) no-repeat center center;
  background-size:contain;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;
  z-index: 1;
}

.weather-align-left {
  left:0;
  right:auto;
}

.weather-align-right {
  right:0;
  left:auto;
}

.weather-mode-always {
  left:0;
  right:auto;
}

.weather-mode-anim {
  left:-100%;
  right:auto;
  /* animation: weatherSlideInOut 60s infinite; */
}

.weather-mode-anim.weather-align-right {
  right:-100%;
  left:auto;
  /* animation: weatherSlideInOutRight 60s infinite; */
}

.weather-mode-always {
  left:0;
  right:auto;
}

.weather-mode-always.weather-align-right {
  right:0;
  left:auto;
}

.weather-mode-chat {
  left:-100%;
  right:auto;
}

.weather-mode-chat.weather-align-right {
  right:-100%;
  left:auto;
}

@keyframes weatherSlideInOut {
  0% {
    left: -100%;
  }
  28% {
    left: -100%;
  }
  34% {
    left: 0%;
  }
  56% {
    left: 0%;
  }
  62% {
    left: -100%;
  }
  100% {
    left: -100%;
  }
}

@keyframes weatherSlideInOutRight {
  0% {
    right: -100%;
  }
  28% {
    right: -100%;
  }
  34% {
    right: 0%;
  }
  56% {
    right: 0%;
  }
  62% {
    right: -100%;
  }
  100% {
    right: -100%;
  }
}

.weather-vertical {
  top:100px;
  min-width:170px;
  text-align: center;
  color:#fff;
}

.weather-minimal {
  top:50px;
  text-align: center;
  color:#fff;
  padding-left:20px;
}

.weather-vertical .weather-inner {
  margin-top:1px;
  border-top:7px solid #68acdc;
  border-bottom:7px solid #68acdc;
}

.weather-vertical .weather-inner-top {
  border-top:16px solid #abc347;
  position: relative;
  z-index: 5;
}

.weather .location {
  display: block;
  padding:10px 15px;
  text-transform: uppercase;
  font-weight: 600;
  color:#68acdc;
  font-size:1.4em;
  letter-spacing: 0.5px;
  text-shadow: 0 0 2px #000;
}

.weather-minimal .location {
  padding:0 0 15px 30px;
  text-align: center;
  min-width:200px;
  color:#00b4f1;
  font-weight: 700;
}

.weather-vertical .current {
  background:rgba(255,255,255,0.1);
  padding:10px 0;
}

.weather-minimal .current {
  margin-top:-20px;
}

.weather-vertical .current-icon, .weather-vertical .extras-row, .weather-minimal .current-icon, .weather-minimal .extras-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.weather-vertical .current-icon .icon {
  width:80px;
  height:80px;
  overflow: hidden;
}

.weather-vertical .current-icon .icon img {
  margin-top:-5px;
  margin-left:-5px;
  width:90px;
  height:90px;
}

.weather-vertical .extras-row {
  padding:8px 0;
}

.weather-vertical .extras {
  padding:8px 0;
}

.weather-vertical .extras-row .icon img {
  max-width:30px;
  height:auto;
}

.weather-vertical .extras-row.humidity .icon img {
  max-width:24px;
  height:auto;
}

.weather-vertical .extras-row .icon {
  padding-right:10px;
}

.weather-vertical .extras-row .info {
  min-width:50px;
  font-weight: 600;
  font-size:1.5em;
  text-shadow: 0 0 2px #000;
}

.weather-vertical .current-icon .icon {
  margin-left:-12px;
}

.weather-vertical .temp {
  padding-left:5px;
}

.weather-minimal .temp {
  padding-left:0;
}

.weather-vertical .temp span, .weather-minimal .temp span {
  display: block;
  font-weight:600;
  text-align: left;
  letter-spacing: 0.5px;
  text-shadow: 0 0 2px #000;
}

.weather .tempf {
  font-size:2.25em;
}

.weather .tempc {
  margin-top: -12px;
  font-size:1.5em;
  color:#c8c8c8;
}

.weather .conditions {
  text-transform: uppercase;
  font-weight: 600;
  color:#abc347;
  display: block;
  padding:5px 20px 0;
  max-width:167px;
  margin:0 auto;
  text-align: center;
  font-size:1.3em;
  line-height: 1em;
  letter-spacing: 0.5px;
  text-shadow: 0 0 2px #000;
}

.weather-minimal .conditions, .weather-minimal .extras {
  display:none;
}

.overlay-key, .chatbot, .add-mods, .league-info {
  text-align: center;
  background-color:#ebebeb;
  padding:50px;
  margin-bottom:50px;
}

.league-info .cols {
  padding:0 150px;
  text-align: left;
}

.verified-catch {
  background-color:#ebebeb;
  padding:40px 40px 20px;
  margin:10px 0 30px;
}

.settings-form .verified-catch-label, .verified-header {
  display:flex;
  align-items: center;
  margin:0;
}

.settings-form .verified-catch-label {
  padding-left:15px;
}

.verified-catch-label span {
  display: inline-block;
}

.verified-catch p {
  font-size:1.5em;
  margin-top:10px;
}

.verified-catch-label span.material-icons {
  font-size: 32px;
  margin-left:5px;
  color:#00b4f1;
}

.settings-form input {
  padding:10px 15px 8px;
  font-size:1.2em;
}

.overlay-key input, .add-mods input {
  width:80%;
}

.overlay-key label {
  padding-top:10px;
}

.settings-form label {
  display: block;
  font-size: 1.2em;
  font-weight: 600;
}

.settings-weather, .settings-general {
  margin-bottom:75px;
}

.settings-weather .formline, .settings-catch .formline, .settings-general .formline {
  padding-top:20px;
}

.settings-weather button.btn, .settings-catch button.btn, .settings-general button.btn {
  margin-top:20px;
  padding:7px 30px 3px;
}

#root .navbar-nav.navbar-main li a .nav-label {
  padding-left:5px;
}

.settings-weather select, .settings-catch select, .settings-general select {
  padding:10px 15px 8px;
}

.mod-list {
  font-size: 1.35em;
}

.mod-list a {
  text-decoration: underline;
  color:#00b4f1;
}

h2.mod-title {
  color: #265aa5;
  text-transform: uppercase;
  font-size: 3em;
  font-weight: 700;
  padding-bottom:20px;
  margin-top:0;
  padding-top:0;
}

.mod-results ul, .current-mods ul, .fish-results ul {
  width:80%;
  margin:0 auto;
  padding:0;
  list-style: none;
  text-align: left;
}

.results-box {
  position: relative;
  z-index: 99;
}

.fish-results ul {
  width: 100%;
  background:#f4f4f4;
  position: absolute;
  z-index: 2;
  border-bottom:1px solid #0078d7;
  border-left:1px solid #0078d7;
  border-right:1px solid #0078d7;
}

.mod-results li, .current-mods li, .fish-results li {
  padding:10px 10px;
  border-bottom:2px solid #cbd0e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fish-results li {
  display: flex;
  align-items: center;
  justify-content:left;
  cursor: pointer;
}

.fish-results li.add-new {
  background:#e4e4e4;
}

.species-form {
  margin-bottom:50px;
}

.species-form .formline {
  padding-bottom:20px;
}

.species-form .btn-cancel {
  margin-left:0;
}

.selected-species {
  display: flex;
  align-items: center;
  justify-content:left;
  cursor: pointer;
  background:#e6ebf0;
  border:1px solid #0078d7;
  padding:10px 15px;
}

.selected-species img {
  width:150px;
  height:auto;
}

.selected-species > span {
  display: inline-block;
  font-weight: 600;
  font-size: 1.5em;
  padding-left:10px;
}

.selected-species button.btn {
  margin:0 0 0 auto;
  padding:5px;
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content:center;
}

.selected-species button.btn span {
  font-size:1.25em;
}

.fish-results li:hover {
  background:#e6ebf0;
}

.fish-results li img {
  width:150px;
  height:auto;
}

.fish-results li span {
  display: inline-block;
  padding-left:10px;
}

.mod-results li:last-child, .fish-results li:last-child {
  border-bottom: none;
}

.mod-results li span, .current-mods li span, .fish-results li span {
  font-size:1em;
  font-weight: 500;
}

.current-mods li span {
  color:#265aa5;
  font-weight: 600;
}

.current-mods li .btn span {
  color:#fff;
}

.mod-results li button.btn-primary, .current-mods li button.btn-error {
  font-size: 1.3em;
  padding:5px 15px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mod-results li .btn-text, .current-mods li .btn-text {
  padding:3px 0 0 5px;
  font-weight: 700;
}
.settings-form .react-datetime-picker select {
  width:auto !important;
}
.settings-form .react-datetime-picker input {
  padding:5px 10px;
}

.settings-form .react-datetime-picker {
  width:100%;
}
.settings-form .col-2 .react-datetime-picker input, .settings-form .col-2 .react-datetime-picker select {
  width:auto;
}

.rdtPicker .rdtTimeToggle {
  background:#ddd;
  font-size: 1.5em;
  cursor: pointer;
}

.rdtTime .rdtSwitch {
  text-align: center;
  background:#ddd;
  font-size: 1.5em;
  cursor: pointer;
}

.rdtPicker {
  bottom:100%;
}

.rdtTime td {
  text-align: center;
}

.rdtTime {
  text-align: center;
}

.rdtTime .rdtCounters {
  margin:0 auto;
}

.settings-form .form-control {
  border:1px solid #231f20;
  border-radius: 0;
  height: auto;
}

.pending-catches {
  background: #e6ebf0;
  border: 1px solid #0078d7;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-bottom:15px;
}

.pending-catches a {
  display: flex;
  width:100%;
  align-items: center;
}

.pending-catches a:hover {
  text-decoration: none;
}

.pending-count {
  display:inline-block;
  background:#cc4949;
  color:#fff;
  font-weight: 700;
  line-height: 1em;
  padding:5px 8px 3px;
  border-radius: 10px;
  margin-left:10px;
}

.pending-zero {
  background:#00b4f1;
}

.admin-text {
  font-size: 1.5em;
  color:#231f20;
  line-height: 1em;
}

.pending-catches .btn-primary {
  margin-left:auto;
  color:#fff;
  background:#00b4f1;
  padding:3px 30px 1px;
  font-weight: 700;
  text-transform: uppercase;
  border:none;
  border-radius: 0;
  font-size: 30px;
  transition: all 0.5s;
}

.pending-catches .btn-primary:hover {
  background:#b1cc49;
}

.catches-list {
  margin-top:20px;
}

.caught-fish {
  background: #e6ebf0;
  border: 1px solid #0078d7;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-bottom:15px;
  position: relative;
  z-index: 1;
}

.admin-fish {
  display: block;
  padding-top: 30px;
}

.caught-fish-inner {
  display: flex;
  align-items: center;
}

.pending-list .caught-fish {
  padding:30px 20px 10px;
  flex-flow: row wrap;
}

.pending-list .btn-edit {
  background: #b1cc49;
}

.caught-fish .username {
  position: absolute;
  z-index: 2;
  top:0;
  left:0;
  background:#265aa5;
  color:#fff;
  text-transform: uppercase;
  line-height: 1em;
  font-weight: 700;
  padding:5px 35px 3px;
  font-size: 1.1em;
}

.caught-fish .fish-image {
  width:20%;
  padding:10px 0 8px;
}

.caught-fish .fish-image img {
  width:100%;
  height:auto;
}

.caught-fish .fish-name {
  text-align: center;
  font-size: 1.3em;
  font-weight: 700;
  color:#00b4f1;
  text-transform: uppercase;
}

.caught-fish .fish-weight {
  font-size: 2em;
  font-weight: 600;
  width:14%;
  margin-left:4%;
  text-align: center;
  color:#265aa5;
}

.caught-fish .fish-weight span {
  display: block;
  line-height: 1.2em;
}

.caught-fish .fish-length {
  font-size: 0.8em;
  color:#5c6064;
}

.caught-fish .fish-datetime {
  font-size: 1.25em;
  font-weight: 500;
  width:18%;
  text-align: center;
}

.caught-fish .fish-verified {
  display: flex;
  align-items: center;
}

.caught-fish .fish-verified span {
  display: inline-block;
}

.caught-fish .fish-verified span.fish-verified-label {
  font-size: 1.25em;
  font-weight: 600;
  color:#00b4f1;
  text-transform: uppercase;
  line-height: 1em;
  padding-top:1px;
}

.caught-fish .fish-verified span.material-icons {
  font-size: 32px;
  margin-right:5px;
  color:#00b4f1;
}

.caught-fish .fish-verified.fish-verified-true span.fish-verified-label, .caught-fish .fish-verified.fish-verified-true span.material-icons {
  color:#abc347;
}

.caught-fish .fish-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:auto;
  text-align: right;
  min-width: 100px;
}

.caught-fish .fish-buttons button.btn, .caught-fish .fish-buttons a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  margin-left:auto;
}

.caught-fish .fish-buttons button.btn span.material-icons, .caught-fish .fish-buttons a.btn span.material-icons, .caught-fish .fish-buttons .fish-locked span.material-icons {
  font-size: 32px;
}

.caught-fish .fish-buttons .fish-locked  {
  background:#231f20;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  margin-right: 15px;
}

.caught-fish .fish-buttons .fish-locked span.material-icons {
  color:#fff;
}

.caught-fish .fish-buttons .btn {
  margin-right:15px;
}

.caught-fish .delete-confirm {
  display: block;
  text-align: center;
  padding-right:15px;
  width:100%;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.25em;
}

.caught-fish .edit-confirm {
  display: block;
  text-align: center;
  padding:15px 0;
  width:100%;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5em;
  color:#cc4949;
}

.edit-confirm-container {
  flex: 0 0 100%;
  background: #d8e0e9;
}

.edit-confirm-container .settings-form {
  padding:0 25px 25px;
}

.edit-confirm-container .formline {
  padding-bottom:20px;
}

.edit-confirm-container .form-buttons {
  padding-top:5px;
}

.btn-edit-catch {
  margin-right:15px;
}

.catches-card-overlay, .rank-card-overlay {
  position: absolute;
  z-index: 10;
  left:0;
  bottom:80px;
  width:100%;
  height:240px;
  opacity: 0;
}

.catches-card {
  width: 400px;
  height: 240px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  z-index: 2;
  bottom:0;
  left:0;
}

.rank-mask {
  margin-left:0;
  height: 250px;
  width:2000px;
  z-index: 1;
  transform: skew(-20deg);
  position: absolute;
  bottom:0;
  left: 355px;
  overflow: hidden;
  white-space: nowrap;
}

.catches-player, .catches-fish {
  margin-left:-3%;
  height: 200px;
  width:450px;
  z-index: 1;
  transform: skew(-20deg);
  position: absolute;
  bottom:0;
  left:405px;
}

.rank-mask .catches-player {
  margin-left:0;
  height: 250px;
  width:528px;
  transform: skew(0deg);
  position: static;
  padding-top:50px;
}

.catches-fish.no-league {
  left: 310px;
  margin-left:0;
}

.catches-fish {
  margin-left:-2%;
  height: 171px;
  width:800px;
  position: absolute;
  bottom:0;
  left:797px;
}

.catches-card .logo-img {
  width:85%;
  height: 80%;
  padding-left:20%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.catches-card .logo-img:before {
  content: " ";
  width:120%;
  height:100%;
  opacity: 0.95;
  position: absolute;
  z-index: 2;
  background:url(/img/weather-bg.jpg) repeat;
}

.catches-player .player-info {
  width:100%;
  height:100%;
  position: relative;
  z-index: 1;
}

.rank-card-overlay .player-info-inner {
  display: flex;
  align-items:center;
}

.rank-card-overlay .catches-player .player-info {
  height:200px;
}

.rank-card-overlay .catches-player .player-rank {
  display: inline-block;
  color:#111319;
  background: #abc347;
  font-weight: 700;
  font-size: 2em;
  line-height: 1em;
  position: relative;
  z-index: 2;
  transform: skew(20deg);
  margin-left:35px;
  text-align: center;
  width:80px;
  padding:10px 0;
  margin-top:18px;
}

.rank-card-overlay .catches-player .player-rank span {
  display: block;
  text-align: center;
}

.rank-card-overlay .catches-player .player-rank span.rank-tied {
  display: block;
  text-align: center;
  font-size: 0.75em;
  color:#515d21;
  text-transform: uppercase;
  line-height: 1em;
}

.catches-card-overlay .player-photo, .rank-card-overlay .player-photo {
  position: absolute;
  z-index: 3;
  bottom:30px;
  left:345px;
  width:210px;
  height:215px;
  display: flex;
  align-items: baseline;
  justify-content: baseline;
}

.rank-card-overlay .player-photo {
  left:0;
  transform: skew(20deg);
  left:65px;
}

.catches-card-overlay .player-photo img, .rank-card-overlay .player-photo img {
  width:100%;
  height:auto;
  margin-top: auto;
}

.catches-player .player-info:before {
  content: " ";
  width:120%;
  height:100%;
  margin-left:-10%;
  opacity: 0.9;
  position: absolute;
  z-index: 2;
  background:url(/img/weather-bg.jpg) repeat;
  transform: skew(20deg);
}

.catches-fish .fish-catch-count {
  margin-left: 70px;
  width:24%;
  height:24%;
  transform: skew(-20deg);
  overflow: hidden;
  position: absolute;
  z-index: 2;
  background: #afc656;
  top:0;
  left:29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.catches-fish .fish-catch-count .fish-catch-count-text {
  display: inline-block;
  transform: skew(20deg);
  text-transform: uppercase;
  font-size: 1.2em;
  line-height: 1em;
  font-weight: 700;
  padding-top: 2px;
  color:#272b3a;
}

.catches-fish .fish-catch-count .fish-catch-count-label {
  font-weight: 500;
  padding-left:3px;
}

.catches-fish .fish-catch-scroller {
  margin-left: 70px;
  width:88%;
  height:76%;
  transform: skew(-20deg);
  overflow: hidden;
  position: absolute;
  z-index: 2;
  bottom:0;
  left:0;
}

.catches-fish .fish-catch-scroller-inner {
  width:110%;
  height:100%;
  transform: skew(20deg);
  margin-left: -3%;
}

.catches-fish .fish-catch-scroller-inner:before {
  content: " ";
  width:120%;
  height:100%;
  margin-left:-10%;
  opacity: 0.85;
  position: absolute;
  z-index: 2;
  background:url(/img/weather-bg.jpg) repeat;
}

.catches-player .player-info:after {
  content: " ";
  width:100%;
  height:12%;
  bottom:0;
  left:0;
  position: absolute;
  z-index: 2;
  background:#63aadd;
}

.catches-player .player-info .player-info-inner:before {    
  content: "";
  background:#63aadd;
  position: absolute;
  top: 0px;
  right: 2.5%;
  z-index: 11;
  width:6%;
  height:100%;
  transform: skew(0deg);
}

.catches-player .player-info .player-info-inner:after {    
  content: "";
  background:#afc656;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 11;
  width:3%;
  height:100%;
  transform: skew(0deg);
}

.catches-card .logo-img img {
  width:75%;
  height: auto;
  position: relative;
  z-index: 3;
}

.catches-card .logo-text {
  position: absolute;
  z-index: 10;
  left:24%;
  bottom:0;
  display: block;
  z-index: 12;
  width:70%;
  text-align: center;
  transform: skew(20deg);
  color:#fff;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1em;
  padding:16px 0 13px;
  text-transform: uppercase;
  background:#63aadd;
}

.player-info-skew {
  width: 277px;
  height: 127px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  z-index: 2;
  margin:25px 0 0 108px;
}

.rank-card-overlay .player-info-skew {
  margin-left:65px;
}

.player-info-skew-inner {
  display: block;
  height : 100%;
  width : 100%;
  margin : 0;
  position:relative;
  overflow: hidden;
  background: rgba(17,19,25,0.45);
}

.player-info-skew .player-name {
  display: block;
  text-align: right;
  background: #65727b;
  line-height: 1em;
  padding:15px 0 12px;
}

.player-info-skew .player-name span {
  display: inline-block;
  transform: skew(20deg);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.4em;
}

.player-info-skew .player-name .player-name-text {
  color:#fff;
  padding-left: 10px;
  padding-right: 20px;
}

.player-info-skew .player-league .player-league-division .player-name-rank {
  color:#fff;
  transform: skew(0deg);
  display: inline-block;
  padding-right:10px;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.3em;
}

.player-info-skew .player-league .player-league-division .player-name-division-text {
  transform: skew(0deg);
  display: inline-block;
  color:#afc656;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.3em;
  padding:0;
}

.player-info-skew .player-league {
  display: block;
  text-align: right;
  line-height: 1em;
  padding:15px 0 12px;
}

.player-info-skew .player-league span {
  display: block;
  transform: skew(20deg);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.3em;
  line-height: 1.3em;
  color:#fff;
  padding-right:20px;
}

.player-info-skew .player-league .player-league-name {
  color:#8e99a0;
}

.player-info-skew .player-league .player-league-division {
  color:#afc656;
  font-weight: 700;
}

.skew {
  display: block;
  height : 100%;
  width : 100%;
  margin : 0 auto 0 -45px;
  position:relative;
  transform: skew(-20deg);
  overflow: hidden;
}

.skew .skew-inner {
  width:100%;
  transform: skew(20deg);
  height:100%;
}

.skew:after {    
  content: "";
  background:#afc656;
  position: absolute;
  top: 0px;
  left: 11%;
  z-index: 11;
  width:3%;
  height:100%;
  transform: skew(20deg);
}

.catches-player .skew:before, .catches-fish .skew:before {
  display: none;
}

.skew:before {    
  content: "";
  background:#63aadd;
  position: absolute;
  top: 0px;
  left: 14%;
  z-index: 11;
  width:5.5%;
  height:100%;
  transform: skew(20deg);
}

.catches-player .skew:after, .catches-fish .skew:after {
  width:84%;
  height:12%;
  top:auto;
  bottom:0;
  background:#63aadd;
}
.catches-fish .skew:after {
  height:10%;
  width:94%;
  left: 3%;
}

.skew .skew-inner:after {
  content: "";
  background:#afc656;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 11;
  width:3.5%;
  transform: skew(-20deg);
  height:100%;
}

.skew .skew-inner:before {
  content: "";
  background:#63aadd;
  position: absolute;
  top: 0px;
  right: 3%;
  z-index: 11;
  width:6.5%;
  transform: skew(-20deg);
  height:100%;
}

.catches-fish .skew {
  transform: skew(0deg);
}

.catches-fish .skew .skew-inner:before {
  right: 1.6%;
  width:3.6%;
  height:90%;
  bottom:0;
  top:auto;
}

.catches-fish .skew .skew-inner:after {
  right: 0;
  width:2%;
  height:90%;
  bottom:0;
  top:auto;
}

.fish-catch-scroll {
  position: relative;
  z-index: 3;
  height: 88%;
  width:9999%;
}

.fish-catch-scroll-item {
  height:100%;
  display: inline-flex;
  align-items:center;
  position: relative;
}

.catch-list-item {
  height:90px;
  display: flex;
  align-items:center;
  justify-content: space-evenly;
  position: relative;
}

.catch-list-item {
  height:120px;
}

.fish-catch-scroll-item::after {
  content: "";
  background:#111319;
  position: absolute;
  top: 0;
  right: -5%;
  z-index: 11;
  width:1%;
  opacity: 0.3;
  transform: skew(-20deg);
  height:100%;
}

.fish-catch-scroll-item:last-child::after {
  display: none;
}

.fish-catch-scroll-item .fish-catch-number {
  display: inline-flex;
  flex-flow:column wrap;  
  align-items: center;
  justify-content: center;
  width:160px;
  padding:0 0 0 50px;
  height: 100%;
  text-align:center;
}

.fish-catch-scroll-item .fish-number, .rank-card .fish-number, .rank-fish-expand .fish-number {
  display: inline-block;
  color:#fff;
  font-weight: 700;
  font-size: 2em;
  line-height: 1em;
  padding:8px 15px 6px;
  margin:0 auto;
  background:rgba(100, 170, 221, 0.3);
}

.rank-card .fish-number {
  font-size:1.2em;
  padding:6px 10px 4px;
}

.rank-fish-expand .fish-number {
  font-size:2em;
  padding:10px 20px 7px;
  min-width: 60px;
  text-align: center;
  background: #39404b;
}

.fish-catch-scroll-item .fish-clip {
  display: inline-block;
  color:#97a0b2;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.3em;
  line-height: 1em;
  padding:8px 15px 6px;
  margin:10px auto 0;
  background:rgba(20, 25, 33, 0.3);
}

.fish-catch-scroll-item .fish-catch-species, .rank-card .fish-catch-species, .rank-fish-expand .fish-catch-species {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  width:200px;
  height: 100%;
  text-align:center;
}

.rank-card .fish-catch-species, .rank-fish-expand .fish-catch-species {
  width:130px;
}

.rank-fish-expand .fish-catch-species {
  width:170px;
}

.fish-catch-scroll-item .fish-catch-species:before, .rank-card .fish-catch-species:before, .rank-fish-expand .fish-catch-species:before {
  content: "";
  background:url(/img/fish-plaque.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 5%;
  right: 3%;
  z-index: 2;
  width:100%;
  height:100%;
}

.fish-catch-scroll-item .fish-catch-img, .rank-card .fish-catch-img, .rank-fish-expand .fish-catch-img {
  position: absolute;
  z-index: 3;
}

.fish-catch-scroll-item .fish-catch-name {
  padding:5px 20px 3px;
  background:rgba(255, 255, 255, 0.3);
  color:#fff;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1em;
  font-size: 1.5em;
}

.fish-catch-scroll-item .fish-catch-img img, .rank-card .fish-catch-img img, .rank-fish-expand .fish-catch-img img {
  width:100%;
  height: auto;
}

.rank-card .fish-catch-img img, .rank-fish-expand .fish-catch-img img {
  width:130%;
  margin-left:-15%;
  margin-top: 4%;
}

.fish-catch-details {
  padding-left:15px;
  padding-right: 20px;
}

.rank-fish-expand .fish-catch-info-text {
  display: inline-block;
  min-width: 85px;
  text-align: center;
}

.rank-fish-expand .fish-catch-ago {
  display: inline-block;
  min-width: 150px;
  text-align: center;
}

.fish-catch-info > span {
  display: inline-flex;
  color:#fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 1em;
  margin:15px auto 0;
  background:rgba(20, 25, 33, 0.3);
  align-items: center;
}

.fish-catch-info .fish-catch-info-weight {
  margin-right:15px;
}

.fish-catch-info > span .fish-catch-info-text {
  display: inline-block;
  padding:5px 8px 2px 15px;
}

.fish-catch-info > span .fish-catch-info-label {
  display: inline-block;
  padding:5px 8px 3px 8px;
  color:#111319;
  font-size: 0.7em;
  background:rgba(100, 170, 221, 0.4);
  font-weight: 700;
}

.rank-fish-expand .fish-catch-info > span .fish-catch-info-label {
  background: rgba(20, 25, 33, 0.2);
}

.fish-catch-none {
  height:100%;
  display: flex;
  align-items: center;
  padding-left:50px;
}

.fish-catch-none-text {
  color:#fff;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 500;
}

.ranked-person {
  display: flex;
  align-items: center;
  margin-bottom:20px;
}

.ranked-person .rank-place {
  display: inline-block;
  color:#fff;
  font-weight: 700;
  font-size: 2em;
  line-height: 1em;
  padding:10px 15px 8px;
  background:#b1cc49;
  min-width:60px;
  text-align: center;
}

.ranked-person .rank-photo {
  padding:0 20px;
}

.ranked-person .rank-photo img {
  width:auto;
  height:200px;
}

.ranked-person .rank-weight {
  font-weight: 700;
  font-size: 1.75em;
  line-height: 1em;
  color:#231f20;
  padding:0 40px;
}

.ranked-person .rank-fish {
  font-weight: 700;
  font-size: 1.75em;
  line-height: 1em;
  color:#231f20;
  padding:0 40px;
}

.ranked-person .rank-name {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.75em;
  color: #00b4f1;
  line-height: 1em;
}

.rank-card {
  position: absolute;
  opacity: 0;
  bottom:0;
  right:0;
  width:500px;
  border-top:20px solid #adc84c;
}

.rank-card-inner {
  border-top:12px solid #00b4f1;
  border-bottom:12px solid #00b4f1;
}

.rank-card .rank-player {
  display: flex;
  align-items: center;
  justify-content:space-between;
  position: relative;
  z-index: 1;
  background: #2a3241;
}

.rank-card .rank-player::before {
  content: " ";
  width:100%;
  height:100%;
  opacity: 0.5;
  top:0;
  left:0;
  position: absolute;
  z-index: 2;
  background:url(/img/weather-bg.jpg) repeat;
}

.rank-card .logo-img {
  position: relative;
  padding:30px;
  z-index: 3;
}

.rank-card .logo-img img {
  width:160px;
  height:auto;
}

.rank-card .player-info {
  position: relative;
  z-index: 3;
  height:176px;
}

.rank-card .player-photo {
  position: absolute;
  bottom:0;
  right:0;
}

.rank-card .player-name {
  position: absolute;
  z-index: 5;
  bottom:0;
  right:0;
  padding:5px;
  background:rgba(101,114,123,0.85);
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  width:240px;
}

.rank-card .player-name::after {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 46px solid rgba(101,114,123,0.85);
  clear: both;
}

.rank-card .rank-info {
  background:#485260;
  padding:25px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.rank-card .rank-info > div {
  padding:8px 20px 6px 55px;
  background: #39404b;
  color:#fff;
  font-size: 2.5em;
  font-weight: 700;
  text-transform: uppercase;
}

.rank-card .rank-info .stat-rank {
  background-image:url(/img/icon-rank.png);
  background-repeat: no-repeat;
  background-position: 20px center;
}

.rank-card .rank-info .stat-weight {
  background-image:url(/img/icon-weight.png);
  background-repeat: no-repeat;
  background-position: 20px center;
  font-weight: 500;
  font-size: 2em;
  padding:14px 20px 12px 60px;
}

.rank-card .rank-info .stat-fish {
  background-image:url(/img/icon-fish.png);
  background-repeat: no-repeat;
  background-position: 20px center;
  font-weight: 500;
  font-size: 2em;
  padding:14px 20px 12px 70px;
}

.rank-card .league-info {
  background:#202225;
  text-align: center;
  font-size:1.5em;
  color:#fff;
  font-weight: 700;
  text-transform: uppercase;
  padding:6px 0 4px;
  margin:0;
}


.rank-card .league-info .league-region {
  padding-right:3px;
}

.rank-card .league-info .league-name {
  color:#abc347;
  padding-left:3px;
}

.rank-card .catch-info {
  background:#2a2d32;
  text-align: center;
  font-size:1.2em;
  color:#767e84;
  font-weight: 600;
  text-transform: uppercase;
  padding:6px 0 4px
}

.rank-card .catch-label {
  border-bottom:2px solid #767e84;
  height:14px;
  margin:0 20px 15px;
}

.rank-card .catch-label-inner {
  display: inline-block;
  padding:0 10px;
  background:#2a2d32;
}

.rank-card .fish-catch-stats {
  padding-left:15px;
}

.rank-fish-expand .fish-catch-stats {
  font-size: 1.35em;
}

.rank-card .fish-catch-stats span {
  display: block;
  background:none;
  text-transform: none;
  line-height: 1em;
}

.rank-card .fish-catch-details {
  padding:0;
}

.rank-card .fish-catch-info .fish-catch-info-weight {
  margin:0;
}

.rank-card .fish-catch-command {
  color:#fff;
  font-size: 1.2em;
  padding-bottom:3px;
}

.rank-card .fish-catch-info > span .fish-catch-info-text {
  font-size:0.8em;
}

.rank-card .fish-catch-info > span .fish-catch-info-label {
  font-size:0.6em;
}

.rank-fish-expand .fish-catch-info-weight {
  margin:0;
}

.rank-fish-expand .fish-catch-info > span .fish-catch-info-text {
  font-size:1.2em;
}

.rank-fish-expand .fish-catch-info > span .fish-catch-info-label {
  font-size:0.8em;
  padding:10px 8px 7px 8px;
}

.rank-fish-expand .btn-clip {
  padding:5px 20px 1px;
  background: #39404b;
}

.rank-card .catch-list {
  min-height:450px;
}

.alert-msg {
  margin-bottom:50px;
  padding:25px;
  font-size: 1.2em;
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
}

.alert-msg.alert-info {
  background:#cee3ea;
  border:1px solid #93c5d6;
}

.alert-msg .material-icons {
  font-size: 2em;
}

.alert-msg-text {
  padding-left:15px;
}

.alert-msg.alert-success {
  background:#eaf0d1;
  border:1px solid #d7dfb6;
  color:#666b53;
}

.alert-msg.alert-danger {
  background:#e7cbcb;
  border:1px solid #d7b3b3;
  color:#6f2727;
}

.signup-price {
  display: block;
  padding-top: 10px;
  margin-bottom:30px;
  font-size: 1.2em;
  color:#265aa5;
  line-height: 1em;
}

.signup-price-text {
  font-weight: 700;
  font-size: 2em;
}

.signup-cancel {
  display: block;
  font-size: 0.8em;
  color:#2a2d32;
}

.alert-msg a {
  cursor: pointer;
  text-decoration: underline;
}

.prize-pool {
  position: absolute;
  z-index: 2;
  width:100%;
  top:40px;
  left:0;
  text-align: center;
  opacity: 0;
}

.prize-pool-inner {
  margin:0 auto;
}

.prize-logo {
  width:220px;
  height:140px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transform: skew(-20deg);
  overflow: hidden;
}

.prize-logo:before {
  content: " ";
  width:150%;
  height:100%;
  opacity: 0.9;
  margin-left: -25%;
  position: absolute;
  z-index: 2;
  background:url(/img/weather-bg.jpg) repeat;
  transform: skew(20deg);
}

.prize-logo img {
  width:65%;
  height:auto;
  transform: skew(20deg);
  position: relative;
  z-index: 3;
  margin-left:-5%;
}

.prize-bar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.prize-season {
  background: #b1cc49;
  color:#000;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skew(-20deg);
  margin-left:-13px;
  max-width:215px;
  max-height: 33px;
}

.prize-season-text {
  display: inline-block;
  padding:6px 35px 6px;
  font-size: 1.2em;
  line-height: 1em;
  transform: skew(20deg);
}

.prize-bar-container {
  text-align: left;
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 825px;
}

.prize-bar-container-inner {
  padding:20px 25px;
  background: #5c6880;
  transform: skew(-20deg);
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-left:5px;
  display: inline-flex;
  align-items: center;
  margin-top: 9px;
}

.prize-bar-container-inner:before {
  content: " ";
  width:150%;
  height:100%;
  opacity: 0.5;
  margin-left: -25%;
  position: absolute;
  z-index: 2;
  top:0;
  left:0;
  background:url(/img/weather-bg.jpg) repeat;
  transform: skew(20deg);
}

.prize-bar-bar-outer {
  height:25px;
  width:600px;
  background:#030303;
  position: relative;
  z-index: 3;
}

.prize-bar-bar-inner {
  height:25px;
  width:300px;
  background:#299ed6;
  transition: all 2s;
  transition-timing-function: ease-out;
}

.prize-amount {
  position: relative;
  z-index: 3;
  transform: skew(20deg);
  padding-left:20px;
  display: inline-flex;
  align-items: center;
  min-width: 240px;
  text-align: center;
  justify-content: center;
}

.prize-amount-text {
  color:#fff;
  font-weight: 600;
  line-height: 1em;
  font-size: 2em;
}

.prize-cta {
  background: #299ed6;
  color:#000;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width:310px;
  margin-left:290px;
}

.prize-cta-inner {
  display: inline-block;
  padding:8px 35px 6px;
  font-size: 1.75em;
  line-height: 1em;
}

.prize-cta-inner span {
  font-weight: 900;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.prize-distro {
  margin-top:10px;
}

.prize-distro-inner {
  display: inline-block;
  background:#17181d;
  color:#fff;
  padding:15px 40px 25px;
  line-height: 1em;
}

.prize-subleague {
  display: inline-block;
  padding-left: 100px;
}

.prize-subleague:first-child {
  padding-left: 0;
}

.prize-donors {
  padding-top:25px;
  color:#fff;
}

.prize-donors-inner {
  display: inline-flex;
  align-items: center;
  background:#17181d;
}

.prize-donors-label, .prize-donors-list, .prize-donor {
  display: inline-block;
}

.prize-donors-label {
  background: #aac341;
  color: #17181d;
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 10px 15px 8px;
}

.prize-donors-list {
  padding-top:8px;
}

.prize-donor {
  display: inline-flex;
  align-items: center;
  padding:0 25px;
  line-height: 1em;
}

.prize-place {
  color: #299ed6;
  font-size: 2em;
  font-weight: 500;
  padding-right:10px;
}

.prize-donor-username {
  font-size: 1.5em;
  font-weight: 500;
}

.subleague-heading {
  display: flex;
  align-items: center;
  justify-content: left;
  min-width: 400px;
}

.subleague-heading span {
  display: inline-block;
}

.subleague-heading .subleague-name {
  background:#aac341;
  color:#17181d;
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
  padding:10px 15px 8px;
}

.subleague-heading .subleague-name.subleague-open {
  background:#fff;
}

.subleague-heading .subleague-regions {
  color:#c6c6c6;
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
  padding-left:15px;
}

.prize-payouts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top:30px;
  position: relative;
  z-index: 1;
}

.prize-placement {
  color:#b1cc49;
  font-size: 2.7em;
  font-weight: 500;
  padding-right: 15px;
}

.prize-money {
  font-size: 1.8em;
  font-weight: 500;
}

.prize-payout {
  display: flex;
  align-items: center;
}

.prize-payout span {
  display: inline-block;
}

.prize-payouts .prize-cta-container {
  position: absolute;
  z-index: 2;
  top:140%;
  left:0;
  width:100%;
}

.prize-payouts .prize-cta {
  margin-left: 0;
  max-width: none;
  text-align: center;
  display: inline-block;
  font-size: 1.75em;
  line-height: 1em;
  padding:8px 15px 5px;
}

.prize-payouts .prize-cta span {
  font-weight: 900;
}

.modal-bg {
  width: 100%;
  height: 100%;
  background: rgba(18, 30, 39, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
}

.modal {
  position: absolute;
  top: 20%;
  left: 50%;
  height:auto;
  width:80%;
  transform: translateX(-50%);
}

.modal p {
  font-size: 1.2em;
}

.modal-footer {
  border:none;
  padding:20px 0 50px;
}

.modal-content {
  border:none;
  padding:50px;
}

a.terms-subscriptions {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 20px;
  font-weight: 500;
  font-size: 1.3em;
  color:#fff;
}

.modal ol {
  font-size: 1.2em;
}

footer p.terms-links {
  font-size: 1.05em;
  padding:0;
  margin:10px 0 0;
  line-height: 1em;
  color:#00b4f1;
}

footer p.terms-links a {
  color:#00b4f1;
}

.free-trial {
  display: block;
  font-size: 1.2em;
  line-height: 1em;
  padding:8px 0 0;
}

.modal .formline {
  margin-bottom:20px;
}

.alert-msg a {
  color:#265aa5;
  text-decoration:underline;
}

.ranking-tabs-container {
  position: relative;
  z-index: 2;
}

.ranking-tab {
  display: inline-block;
}

.ranking-tab span {
  background:#dee3e4;
  color:#657378;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1em;
  font-size: 2em;
  cursor: pointer;
  padding:23px 50px 18px;
  display: inline-block;
  border-bottom:3px solid #a7be50;
}

.ranking-tab.active span {
  color:#a7be50;
  border-bottom:3px solid #f1f1f1;
  background:#f1f1f1;
}

.ranking-container {
  border-top:3px solid #a7be50;
  position: relative;
  z-index: 1;
  margin-top:-3px;
  background:#f1f1f1;
}

.subleague-bar {
  margin-top:30px;
  background:#535758;
  color:#00b4f1;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1em;
  font-size: 2em;
  text-align: center;
}

.subleague-bar span {
  display: inline-block;
  padding:10px 0 7px;
}

.rank-user.in-prize {
  background:#e9eed8;
}

.rank-user.in-prize .rank-user-inner {
  border-top: 1px solid #d9e3b8;
}

.rank-user-inner {
  padding:20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:#7e8486;
  border-top:1px solid #e9e9e9;
}

.rank-place {
  background:#7e8486;
  color:#fff;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1em;
  font-size: 2em;
  text-align: center;
  padding:13px 15px 10px;
}

.in-prize .rank-place {
  background:#78805e;
}

.rank-prize {
  background:#e8eced;
  padding:13px 15px 10px;
  text-align: center;
}

.in-prize .rank-prize {
  background:#e0e6ca;
  color:#78805e;
}

.rank-prize span {
  display: block;
  font-weight: 500;
  font-size: 1.2em;
}

.rank-prize span.prize-text {
  font-weight: 700;
}

.rank-person {
  display: flex;
  align-items: center;
  min-width:400px;
}

.rank-person .rank-photo img {
  width:150px;
  height:auto;
}

.rank-divider {
  height:3px;
  background:#a7be50;
}

.rank-username a {
  display: flex;
  align-items: center;
  color:#7e8486;
  text-decoration: none;
}

.in-prize .rank-username a {
  color:#78805e;
}

.rank-username span {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1em;
  font-size: 1.5em;
  padding-right:15px;
}

.rank-donate {
  background:#e8eced;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1em;
  font-size: 1.2em;
  text-align: center;
  color:#7e8486;
  padding:10px 0 8px;
}

.rank-donate span {
  padding-right:15px;
}

.rank-donate a.btn {
  font-size: 1em;
  padding:3px 20px 1px;
}

.rank-bag {
  background:#e8eced url(/img/icon-weight-gray.png) no-repeat 25px center;
  padding:13px 25px 10px 70px;
  text-align: center;
  font-weight: 500;
  font-size: 1.5em;
  text-transform: uppercase;
  min-width: 170px;
}

.in-prize .rank-bag {
  background:#e0e6ca url(/img/icon-weight-green.png) no-repeat 25px center;
  color:#78805e;
}

.rank-fish {
  background:#e8eced url(/img/icon-fish-gray.png) no-repeat 20px center;
  padding:13px 25px 10px 70px;
  text-align: center;
  font-weight: 500;
  font-size: 1.5em;
  text-transform: uppercase;
  min-width: 140px;
  display: flex;
  align-items: center;
}

.rank-fish span {
  line-height: 1em;
}

.rank-fish .material-icons {
  cursor: pointer;
  display: inline-block;
  color:#00b4f1;
  font-size: 1.2em;
  margin-left:10px;
  user-select: none;
}

.rank-fish .material-icons.expand-close {
  color:#be5050;
}

.rank-fish-expand {
  background: #e4e4e4;
}

.rank-fish-expand-inner {
  padding:25px;
}

.rank-username .rank-live {
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 1em;
  padding-top:5px;
}

.rank-username .rank-live-icon {
  background:#be5050;
  border-radius: 50%;
  display: inline-block;
  width:16px;
  height:16px;
  color:#be5050;
}

.rank-username .rank-live-text {
  display: inline-block;
  padding-left:5px;
  font-size: 1.3em;
  color:#be5050;
  padding-top: 3px;
}

.rank-username .viewer-count {
  padding-top: 2px;
  font-size: 1em;
}

.in-prize .rank-fish {
  background:#e0e6ca url(/img/icon-fish-green.png) no-repeat 20px center;
  color:#78805e;
}

.blink_me {
  animation: blinker 4s linear infinite;
}

.league-ends-timer {
  position: absolute;
  z-index: 99;
  top:0;
  left:-30px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.league-ends-text {
  padding:10px 25px 10px 50px;
  background:#a7be50;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transform: skew(-20deg);
  overflow: hidden;
}

.league-ends-text span {
  position: relative;
  z-index: 3;
  color:#202225;
  transform: skew(20deg);
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 700;
}

.league-ends-time {
  padding:10px 25px;
  background: #00b4f1;
  transform: skew(-20deg);
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  color:#fff;
  font-weight: 700;
  font-size: 1.5em;
}

.league-ends-time .odometer-block {
  transform: skew(20deg);
}

.current-league {
  padding-bottom:100px;
}

.past-league a {
  font-size: 1.4em;
  text-decoration: underline;
  color:#299ed6;
}

.back-rankings {
  padding-bottom:20px;
}

.back-rankings a {
  font-size: 1.4em;
  text-decoration: underline;
  color:#265aa5;
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.refresh-button {
  padding-top:15px;
}

.dono-alert {
  opacity: 0;
  position: absolute;
  z-index: 999;
  top:100px;
  left:0;
  width:100%;
  text-align: center;
}

.dono-alert-inner {
  display: inline-block;
  margin:0 auto;
}

.dono-alert-logo {
  margin:0 auto 20px;
}

.dono-alert-text {
  padding:15px 25px;
  background: #202225;
  color:#fff;
  font-size: 2em;
  font-weight: 600;
}

.dono-username {
  color:#299ed6;
}

.dono-amount {
  color:#a7be50;
}

.alert-msg a.btn {
  display: inline-block;
  color:#fff;
  text-decoration: none;
  font-size: 1.2em;
  line-height: 1em;
  padding:8px 15px 5px;
  margin-left:15px;
}

.admin-by {
  width:100%;
  text-align: center;
  border:1px solid #00b4f1;
  background:#dae0e6;
  padding:10px 0;
  font-size: 1.2em;
  font-weight: 500;
  margin-top:10px;
}

.admin-rejected {
  background:#e6d0cb;
}

.admin-approved {
  background:#d6e6cb;
}

.catch-admin-history {
  padding-top:60px;
}

  @media screen and (max-width: 1199px) {
    .carousel .control-dots .dot {
      width:12px;
      height:12px;
    }
    .carousel .control-dots {
      bottom:40px;
      padding-right: 130px;
    }
  }

  @media screen and (max-width: 991px) {
    .container, .container-sm, .container-md {
      max-width: 90%;
    }
    .hero-counter {
      padding-top:20px;
      display: block;
      text-align: center;
    }
    .counter {
      min-width: auto;
      padding-right:0;
    }
    .hero-counter a {
      margin:10px auto 10px;
    }
    .hero h1 {
      font-size: 70px;
    }
    .hero h2 {
      font-size: 38px;
    }
    .hero-counter .odometer-block > span {
      font-size:30px;
    }
    .hero-counter .odometer-block > span > span {
      font-size: 44px;
      line-height: 44px;
      min-width: 44px;
    }
    .timer-label {
      font-size: 24px;
    }
    .carousel .control-dots {
      top:-35px;
      bottom: auto;
      padding-right: 120px;
    }
    .navbar-brand img {
      max-width:180px;
    }
    .navbar {
      padding-left:0;
      padding-right:0;
    }
    #root .navbar-nav.navbar-main {
      display: none;
      margin:0;
      padding:35px 0 0;
      width:100%;
    }
    #root .navbar-nav.navbar-main.navbar-show {
      display: block;
    }
    #root .navbar-nav.navbar-main li {
      padding:10px 0;
      border-bottom:2px solid #e9e9e9;
    }
    #root .navbar-nav.navbar-main li:last-child {
      border-bottom:none;
    }
    #root .navbar-nav.navbar-main li a {
      display: flex;
      align-content: center;
      width:100%;
    }
    #root .navbar-nav.navbar-main li a .nav-label {
      display: inline-block;
      padding-left:10px;
    }
    .navbar-nav.mobile-menu {
      display: block;
    }
    .mobile-menu .nav-link {
      padding:10px 0 0;
    }
    .steps-bar {
      margin:0 60px;
    }
    .caught-fish .fish-weight {
      font-size: 1.5em;
      width: 12%;
      margin-left: 3%;
    }
    .caught-fish .fish-buttons button.btn span.material-icons, .caught-fish .fish-buttons a.btn span.material-icons, .caught-fish .fish-buttons .fish-locked span.material-icons {
      font-size: 24px;
    }
    .caught-fish .fish-verified span.material-icons {
      font-size: 24px;
    }
    .caught-fish .fish-verified span.fish-verified-label {
      font-size: 1em;
    }
    .league-info .cols {
      padding:0;
      text-align: left;
    }
    .rank-user-inner {
      display: block;
    }
    .rank-person {
      justify-content: center;
    }
    .rank-user-inner {
      padding: 20px 20px;
    }
    .rank-bag, .rank-fish, .in-prize .rank-bag, .in-prize .rank-fish {
      background-image: none;
      padding: 13px 25px 10px;
    }
    .rank-bag span {
      display: inline-block;
      padding-left:40px;
      background:url(/img/icon-weight-gray.png) no-repeat left center;
    }
    .rank-fish {
      justify-content: center;
    }
    .rank-fish span {
      display: inline-block;
    }
    .rank-fish span:first-child {
      padding-left:50px;
      background:url(/img/icon-fish-gray.png) no-repeat left center;
    }
    .in-prize .rank-bag span {
      background-image:url(/img/icon-weight-green.png);
    }
    .in-prize .rank-fish span:first-child {
      background-image:url(/img/icon-fish-green.png);
    }
    .rank-donate {
      padding-bottom:20px;
    }
    .rank-donate span {
      display: block;
      padding:10px 20px;
      text-align: center;
    }
    .rank-username .rank-live {
      display: block;
      text-align: center;
      padding:0 0 10px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .settings-weather input, .settings-weather select, .settings-catch input, .settings-catch select, .settings-general select {
      min-width:300px;
    }
    .settings-weather button.btn, .settings-catch button.btn, .settings-general button.btn {
      min-width:200px;
    }
    .chatbot .chatbot-buttons button:last-child {
      margin-left:25px;
    }
    .settings-form .col-2 {
      width:50%;
      max-width:100%;
      display: inline-block;
      vertical-align: top;
    }
    .settings-form .col-2:nth-child(1) {
      padding-left:0;
    }
    .settings-form .col-2:nth-child(2) {
      padding-right:0;
    }
    .settings-form .col-2 input, .settings-form .col-2 select {
      width:100%;
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      text-align: center;
      padding-left:0;
      padding-right:0;
    }
    .two-up {
      display: block;
    }
    .up {
      display: block;
      padding:0 !important;
    }
    .up:first-child {
      margin-bottom:40px;
    }
    .price-compare {
      display: block;
    }
    .price-compare .compare {
      display: block;
    }
    .price-compare .compare:last-child {
      margin-top:40px;
    }
    .price-compare .compare:first-child {
      display: none;
    }
    h3 {
      font-size: 46px;
    }
    p {
      font-size: 22px;
    }
    .price-compare .compare span {
      font-size: 22px;
    }
    .two-up .up .two-up-inner {
      padding: 20px 25px;
    }
    section {
      padding: 50px 0;
    }
    .pricing, .hero-2 {
      padding:50px 0;
    }
    .carousel-labels {
      margin-bottom: 55px;
      margin-top: -53px;
    }
    .carousel-labels h4 {
      font-size: 34px;
      display: block;
    }
    .steps-bar {
      margin:0 30px;
    }
    .questions {
      width:100%;
      padding:50px;
    }
    .settings-form input, .settings-form select {
      width:80%;
    }
    .settings-weather button.btn, .settings-catch button.btn, .settings-general button.btn {
      width:80%;
      margin:15px auto 0;
    }
    .chatbot-buttons button.btn {
      width:100%;
      margin:15px auto 0;
    }
    .settings-form .col-2 {
      width:100%;
      max-width:100%;
      display: block;
      padding:0;
    }
    .settings-form .col-2 .col-2 {
      width:50%;
      display: inline-block;
    }
    .settings-form .col-2 .col-2:first-child {
      padding-right:10px;
    }
    .settings-form .col-2 .col-2:last-child {
      padding-left:10px;
    }
    .settings-form .col-2 .col-2 input {
      width:100%;
    }
    .mod-results li button.btn-primary, .current-mods li button.btn-error {
      font-size: 1em;
    }
    .mod-results li .btn-text, .current-mods li .btn-text {
      display: none;
    }
    .mod-results li button.btn-primary, .current-mods li button.btn-error {
      padding:10px 10px;
    }
    .mod-results ul, .current-mods ul, .add-mods .settings-form input, .species-form input {
      width:100%;
    }
    .caught-fish, .caught-fish-inner {
      display: block;
      text-align: center;
    }
    .caught-fish .fish-buttons, .caught-fish-inner .fish-buttons {
      text-align: center;
      padding:20px 0;
    }
    .caught-fish .fish-buttons button.btn, .caught-fish .fish-buttons a.btn, .caught-fish .fish-buttons .fish-locked, .caught-fish-inner .fish-buttons button.btn, .caught-fish-inner .fish-buttons a.btn, .caught-fish-inner .fish-buttons .fish-locked {
      margin:0 15px 0 0;
    }
    .caught-fish .fish-buttons .fish-locked, .caught-fish .fish-buttons button.btn-delete, .caught-fish .fish-buttons button.btn-cancel, .caught-fish-inner .fish-buttons .fish-locked, .caught-fish-inner .fish-buttons button.btn-delete, .caught-fish-inner .fish-buttons button.btn-cancel {
      margin:0;
    }
    .caught-fish .fish-image, .caught-fish-inner .fish-image {
      width:auto;
    }
    .caught-fish .fish-image img, .caught-fish-inner .fish-image img {
      max-width:350px;
    }
    .caught-fish .fish-weight, .caught-fish-inner .fish-weight {
      width: auto;
      margin-left:0;
    }
    .caught-fish .fish-datetime, .caught-fish-inner .fish-datetime {
      width: auto;
      text-align: center;
    }
    .caught-fish .fish-verified, .caught-fish-inner .fish-verified {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .caught-fish .fish-name, .caught-fish-inner .fish-name {
      font-size: 1.5em;
    }
    .caught-fish .delete-confirm, .caught-fish-inner .delete-confirm {
      padding-right:0;
    }
    .pending-catches a {
      display: block;
    }
    .pending-catches .admin-text {
      margin:0 auto;
      display: block;
    }
    .pending-catches .btn-primary {
      width:100%;
      margin:15px auto;
    }
    .caught-fish .username {
      width:80%;
      left:10%;
      text-align: center;
    }
    .modal {
      width:90%;
    }
    .modal-content {
      padding:25px;
    }
    .modal-content h4 {
      font-size: 1.7em;
    }
    .modal-content p {
      font-size: 1.25em;
    }
    .modal-footer {
      align-items: center;
      justify-content: space-between;
    }
    .modal-footer a {
      display: inline-block;
      white-space: nowrap;
    }
    a.terms-subscriptions {
      margin-right: 0;
    }
    .rank-fish-expand .catch-list-item {
      display: block;
      height: auto;
    }
    .rank-fish-expand .fish-catch-number {
      margin-bottom:15px;
    }
    .rank-fish-expand .fish-catch-species {
      height:100px;
    }
    .rank-fish-expand .fish-catch-details {
      margin-bottom:15px;
    }
    .rank-fish-expand .fish-catch-stats {
      margin-bottom:10px;
    }
    .rank-fish-expand .btn-clip {
      margin-bottom:100px;
    }
    .rank-fish-expand .catch-list-item:last-child .btn-clip {
      margin-bottom:25px;
    }
    .rank-fish-expand .catch-list-item:nth-child(1) {
      margin-top: 25px;
    }
  }
  
  @media screen and (max-width: 549px) {
    .overlay-key input, .add-mods input {
      width:100%;
    }
    .overlay-key, .chatbot, .add-mods {
      padding:25px;
    }
    .verified-catch {
      padding:20px 20px 10px;
    }
    .rank-person {
      display: block;
      text-align: center;
      min-width: auto;
      padding-top:10px;
    }
    .rank-username a {
      display: inline-block;
      padding:10px 0;
    }
  }
  
  @media screen and (max-width: 449px) {
    .buttons .btn, .species-form button {
      width:100%;
      margin:10px 0 10px;
    }
  }